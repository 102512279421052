window.$ = window.jQuery = require('jquery');

window._ = require('lodash');

import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/resizable';
